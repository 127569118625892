

const isSearchPanelOpen = ref(false)

const toggleSearchPanel = (bool: boolean) => {
  if (isSearchPanelOpen.value) {
    setTimeout(() => { isSearchPanelOpen.value = bool }, 150)
  } else {
    isSearchPanelOpen.value = bool
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSearchPanel = () => ({
  isSearchPanelOpen,
  toggleSearchPanel
})
